import React from "react"
import Article from "../components/article"

export default () => (
  <Article>
    <div>
      <h2>404</h2>
      <p>It look like you are lost. There is nothing to be found here. Go home by <a href="/">Clicking here!</a></p>
    </div>
  </Article>
)